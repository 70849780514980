import React from "react"
import { Link } from "gatsby"
import Styled from "./styled.module.css"

function Hero() {
  return (
    <section className={Styled.section}>
      <div className={`${Styled.heroWrapper} container`}>
        <div className={Styled.heroDescription}>
          <div className={Styled.titleWrapper}>
            <h1 className="lg-title bold text-gray">404</h1>
            <p className="md-text text-gray text-center">
              Sorry. the content you’re looking for doesn’t exist. <br />
              Either it was removed, or you mistyped the link.
            </p>
            <div className={Styled.heroButtonGroup}>
              <Link to="/">
                <button className={Styled.btnPrimary}>Go to homepage</button>
              </Link>
              <Link to="/contact">
                <button className={Styled.btnWhite}>Contact us</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
